/* Container for the Master Category Page */
.master-category-page {
  background-color: #fff;
}

/* Left Section: 10% */
.master-category-page-left-section {
  flex: 1;
  box-sizing: border-box;
  padding-left: 20px;
}

/* Right Section: 90% */
.master-category-page-right-section {
  flex: 5;
  box-sizing: border-box;
  padding: 20px;
}

.master-category-page-main {
  display: flex;
  flex: 1;
  gap: 20px;
}

.master-category-page-left-section .category-page-category-section {
  background-color: #ec0200;
  border-radius: 20px;
  margin: 0 0 20px 0;
  box-sizing: border-box;
}

.master-category-page-left-section .category-page-category-section .category-page-category-title {
  padding: 30px 20px 20px 20px;
  margin: 0;
}

.master-category-page-left-section .category-page-category-section h2 {
  color: white;
}

.master-category-page-left-section .category-page-category-section span {
  color: white;
}

.master-category-page-left-section .category-page-category-section svg {
  display: none;
}

.master-category-page-left-section .category-page-category-title {
  margin: 0 0 20px 0;
}

.master-category-page-left-section .category-page-category-item {
  padding: 0 20px;
}

.master-category-page-left-section .category-page-category-item:hover {
  background-color: #ff8483;
}

.master-category-page .category-page-product-grid-full-width {
  grid-column: 1 / -1; /* Full-width spanning across all grid columns */
  display: flex;
  justify-content: center;
  align-items: center;
}

.master-category-page .pagination-bar {
  margin-top: 30px;
}

/* Full-Width Section */
.master-category-page-full-width-section {
  width: 100%;
  margin-top: 20px;
}


.master-category-page-full-width-section .product-collection-wrapper {
  background: transparent;
}

.master-category-page-full-width-section .collection-title {
  text-align: left;
  padding-left: 30px;
}
