/* Pagination Bar Wrapper */
.slideshow-pagination-bar-wrapper {
  background-color: #ec0200; /* Red background color */
  width: 100%; /* Full width */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Pagination Bar Container */
.slideshow-pagination-bar-container {
  background-color: rgba(0, 0, 0, 0.3); /* Gray translucent background */
  padding: 2px 15px;
  border-radius: 10px 10px 0 0;
  display: flex;
  gap: 10px; /* Space between pause button and dots */
  align-items: center;
}

/* Pause/Play Button */
.slideshow-pagination-bar-pause-play-button {
  color: white; /* Icon color */
  font-size: 18px; /* Size of the icon */
  cursor: pointer; /* Pointer cursor on hover */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px; /* Space between the button and the dots */
}

/* Pagination Dots */
.slideshow-pagination-bar-dot {
  width: 10px;
  height: 10px;
  background-color: #bbb; /* Default dot color */
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.slideshow-pagination-bar-dot.active {
  background-color: #fff; /* Active dot color (white) */
}

.slideshow-pagination-bar-dot:hover {
  background-color: #fff; /* Hover color for dots */
}
