/*

.boxiecat-product {
  align-items: flex-end;
  background-color: #fff8ed;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  height: auto;
  width: 650px;
  box-sizing: border-box;
  position: relative;
}

.carna4-product-circle {
  box-sizing: border-box;
  position: relative;
  background-color: #ec0200;
  border-radius: 50%;
  width: 350px;
}

.boxiecat-product .boxiecat-product-litter,
.boxiecat-product .boxiecat-product-scoop {
  max-width: 100%;
  display: block;
  position: absolute;
}

.carna4-product .boxiecat-product-scoop {
  height: 17%;
  right: 4%;
  top: 5%;
}

.carna4-product .boxiecat-product-litter {
  height: 90%;
  right: 14%;
  top: 9%;
}


*/


.boxiecat-product {
    background-color: #fff3e1;
    box-sizing: border-box;
    height: 380px;
    position: relative;
    width: 380px;
}

.boxiecat-product .boxiecat-litter-image {
    background-color: #ec0200;
    border-radius: 50%;
    height: 300px;
    left: 20%;
    position: absolute;
    top: 0;
    width: 300px;
    z-index: 1;
}

.boxiecat-product .boxiecat-product-litter {
    height: 90%;
    position: absolute;
    top: 15%;
    z-index: 2;
}

.boxiecat-product .boxiecat-product-scoop {
    bottom: 5%;
    height: 35%;
    right: 24%;
    position: absolute;
    z-index: 3;
}

.boxiecat-product .boxiecat-product-scoop-shadow {
    bottom: 0;
    height: 16%;
    right: 20%;
    position: absolute;
    width: auto;
    z-index: 1;
}

