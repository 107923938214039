.master-category-page-side-ad-container {
    display: flex;
    flex-direction: column;
    background-color: #ec0200;
    border-radius: 10px;
    overflow: hidden;
}

.master-category-page-side-ad-image-container {
    width:100%;
    height: auto;
}

.master-category-page-side-ad-image {
    width:100%;
    height: auto;
}

.master-category-page-side-ad-content {
    display: flex; /* Enable Flexbox */
    flex-direction: column; /* Stack content vertically */
    padding: 20px 10px;
    color: white;
    align-items: center; /* Center align all child elements horizontally */
}


.master-category-page-side-ad-title {
  font-size: 21px;
  font-family: "acumin-variable",sans-serif;
  font-variation-settings: 'wght' 738, 'wdth' 100, 'slnt' 0;
  text-align: center;
}

.master-category-page-side-ad-subtitle {
  font-family: "acumin-variable",sans-serif;
  font-size: 21px;
  font-variation-settings: 'wght' 400, 'wdth' 100, 'slnt' 0;
  text-align: center;
}

.master-category-page-side-ad-description {
  font-size: 21px;
  font-family: "acumin-variable",sans-serif;
  font-variation-settings: 'wght' 738, 'wdth' 100, 'slnt' 0;
  text-align: center;
  margin-top: 20px;
}

.master-category-page-side-ad-subdescription {
  font-family: "acumin-variable",sans-serif;
  font-size: 21px;
  font-variation-settings: 'wght' 400, 'wdth' 100, 'slnt' 0;
  text-align: center;
}

.master-category-page-side-ad-button {
  font-family: "acumin-variable",sans-serif;
  font-size: 21px;
  font-variation-settings: 'wght' 700, 'wdth' 100, 'slnt' 0;
  padding: 5px 15px;
  border: none;
  border-radius: 10px;
  color: #ec0200;
  background-color: white;
  margin-top: 20px;
}