/* src/components/SlideshowContainer.css */

.slideshow-wrapper {
  flex: 1; /* Take up the remaining space in the content container */
  display: flex;
  flex-direction: column; /* Stack the slideshow and the bottom bar vertically */
  align-items: center;
  width: 100%; /* Full width of the wrapper */
  height: 100%;
  overflow: hidden; /* Prevent horizontal scrolling */
}

.slideshow-wrapper {
  flex: 1; /* Take up the remaining space in the content container */
  display: flex;
  flex-direction: column; /* Stack the slideshow and the bottom bar vertically */
  align-items: center;
  width: 100%; /* Full width of the wrapper */
  height: 100%;
  overflow: hidden; /* Prevent horizontal scrolling */
}


.slideshow-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  /* Background Image Properties */
  background-image: url('https://cdn.thelittlepetshoppe.com/slideshow-background.png');
  background-size: 100% 100%; /* Stretch the image to fill the container completely */
  background-position: center; /* Center the image within the container */
  background-repeat: no-repeat; /* Avoid repeating the image */
}







.slideshow-border {
  width: 98%; /* Adjust the width of the border area */
  height: 96%; /* Adjust the height of the border area */
  border: 5px solid black; /* Border inside the container */
  padding: 20px; /* Space between the border and the slide content */
  box-sizing: border-box; /* Include padding in the total size */
  position: relative;
}

.slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* You can add styles for your slide content here */
}

.nav-button {
  background-color: rgba(0, 0, 0, 0.5); /* Translucent background */
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  z-index: 10; /* Add this line to ensure buttons are above other elements */
}

.prev-button {
  left: 25px; /* Position 20px from the left edge */
}

.next-button {
  right: 25px; /* Position 20px from the right edge */
}

.nav-button:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Darker background on hover */
}
