
.cookie-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #d8d8d8;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  gap: 20px;
}


.cookie-bar-content h3 {
  font-family: "acumin-variable", sans-serif;
  font-variation-settings: "slnt" 0, "wdth" 100, "wght" 700;
  font-size: 18px;
  margin: 0;
  margin-bottom: 5px;
}

.cookie-bar-content h4 {
  font-family: "acumin-variable", sans-serif;
  font-variation-settings: "slnt" 0, "wdth" 100, "wght" 700;
  font-size: 18px;
  margin: 0;
  margin-bottom: 5px;
}

.cookie-bar-content p {
  font-family: "acumin-variable", sans-serif;
  font-size: 14px;
  color: #333;
  margin: 0;
}


.cookie-bar-buttons {
  display: flex;
  gap: 10px;
}


.cookie-bar-buttons button {
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  border: none;
  font-family: "acumin-variable", sans-serif;
  font-variation-settings: "slnt" 0, "wdth" 100, "wght" 600;
  transition: all 0.3s ease;
}


.cookie-bar-buttons .accept-all {
  background-color: #ec0200;
  color: white;
}

.cookie-bar-buttons .accept-all:hover {
  background-color: #c10100;
}


.cookie-bar-buttons .necessary-cookies {
  background-color: #949599;
  color: white;
}

.cookie-bar-buttons .necessary-cookies:hover {
  background-color: #bbb;
}


.cookie-bar-buttons .manage-cookies {
  background-color: transparent;
  color: #949599;
  border: 1px solid #949599;
}

.cookie-bar-buttons .manage-cookies:hover {
  background-color: #f4f4f4;
}

