.faq {
  max-width: 800px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
  color: #333;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  color: #a52a2a;
}

.faq-question:hover {
  color: #ec0200;
}

.faq-icon {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.faq-answer {
  padding: 10px 0;
  font-size: 14px;
  line-height: 1.6;
  color: #555;
}

.faq-answer-list {
  padding-left: 20px; /* Indent the bullets */
  list-style-type: disc; /* Use bullet points */
  margin: 10px 0;
  color: #555; /* Slightly lighter color for answers */
}

.faq-answer-list li {
  margin-bottom: 5px; /* Add spacing between bullet points */
  font-size: 14px;
  line-height: 1.4;
}
