/* Container holding the expert support and floating menu */
.expert-support-container {
  position: relative; /* For positioning the floating container */
  display: inline-block; /* Allow hover effect */
  font-family: "acumin-variable", sans-serif;
  font-variation-settings: "slnt" 0, "wdth" 105, "wght" 500;
}

/* Triggering Div */
.expert-support {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 11px 0;
  color: #ec0200;
}


/* Floating Container */
.expert-support-container .floating-container {
  position: absolute;
  top: 40px; /* Place below the triggering div */
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 0;
  width: 300px;
  display: none; /* Hidden by default */
  z-index: 10;
}


/* Display the floating container on hover */
.expert-support-container:hover .floating-container {
  display: block;
}


.expert-support-container .floating-content {
  font-family: "acumin-variable", sans-serif;
  text-align: center;
}

.expert-support-container .help-text {
  font-variation-settings: "slnt" 0, "wdth" 105, "wght" 700;
  font-size: 14px;
  padding: 0 40px;
}

.expert-support-container .phone-number {
  font-variation-settings: "slnt" 0, "wdth" 115, "wght" 700;
  font-size: 28px;
  color: #ec0200;
  font-weight: bold;
  margin-bottom: 5px;
  text-decoration: none;
  cursor: pointer;
  padding: 0 15px;
}

.expert-support-container .availability {
  font-size: 14px;
  color: #ec0200;
  margin-top: 0;
  margin-bottom: 15px;
  padding: 0 15px;
}



.expert-support-container .expert-support-footer-links {
  font-size: 14px;
  font-variation-settings: "slnt" 0, "wdth" 105, "wght" 500;
  color: #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
}

.expert-support-container .expert-support-footer-links a {
  color: black;
  text-decoration: none;
}

.expert-support-container .expert-support-footer-links a:hover {
  text-decoration: underline;
}

.expert-support-container .expert-support-footer-links span {
  color: #ccc;
}





.expert-support-container .contact-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5; /* Light gray background for the buttons container */
}

.expert-support-container .chat-live-button,
.expert-support-container .contact-us-button {
  font-family: "acumin-variable", sans-serif;
  font-variation-settings: "slnt" 0, "wdth" 105, "wght" 500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
  height: 100%;
  width: 100%;
  border: none;
}

.expert-support-container .chat-live-button {
  background-color: #eaeaea; /* Light gray background */
  color: #a9a9a9; /* Gray text */
  cursor: not-allowed;
  border-right: 1px solid white;
}

.expert-support-container .chat-live-button:hover {
  background-color: #d4d4d4; /* Slightly darker gray on hover */
}

.expert-support-container .contact-us-button {
  background-color: #ec0200; /* Red background */
  color: white; /* White text */
  border-left: 1px solid white;
  text-decoration: none;
}

.expert-support-container .contact-us-button:hover {
  background-color: #bd0201; /* Slightly darker red on hover */
}

.expert-support-container .chat-live-button .chat-icon {
  fill: #a9a9a9;
  height: 24px;
}

.expert-support-container .contact-us-button .email-icon {
  font-size: 24px;
  margin-right: 7px;
}









/* Wrapper for the phone number */
.expert-support-container .phone-number-wrapper {
  position: relative; /* Needed for absolutely positioning the icon */
  text-align: center; /* Center-align the phone number */
}

/* Phone number styles */
.expert-support-container .phone-number {
  font-variation-settings: "slnt" 0, "wdth" 105, "wght" 700;
  font-size: 22px;
  color: #ec0200;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  padding: 0 15px;
  display: inline-block; /* Ensure the phone number remains inline for proper alignment */
}

/* Phone icon (initially hidden) */
.expert-support-container .phone-icon {
  position: absolute; /* Remove the icon from the document flow */
  left: -15%; /* Place the icon to the left of the phone number */
  top: 55%; /* Vertically center the icon relative to the phone number */
  transform: translateY(-60%); /* Adjust for exact centering */
  font-size: 24px; /* Size of the icon */
  color: #ec0200; /* Match the phone number color */
  opacity: 0; /* Initially hidden */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

/* On hover: Show the icon */
.expert-support-container .phone-number-wrapper:hover .phone-icon {
  opacity: 1; /* Make the icon visible */
  transform: translateY(-50%) translateX(45px); /* Slightly move to the left for animation */
}



.call-icon {
  font-size: 28px;
  font-weight: bold;
  color: #ec0200;
  transform-origin: center;
}


.phone-number-wrapper:hover .call-icon {
  animation: shootArrow 0.5s forwards;
}

@keyframes shootArrow {
  0% {
    transform: translateX(0) scale(1);
  }
  50% {
    transform: translateX(10px) scale(1.2);
  }
  100% {
    transform: translateX(15px) scale(1);
  }
}





/*
.phone-number-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}


.phone-number {
  font-size: 18px;
  color: #ec0200;
  text-decoration: none;
  transition: color 0.3s ease;
}


.phone-number-wrapper:hover .phone-number {
  color: #c10100;
}

.phone-icon {
  margin-right: 10px;
  opacity: 0;
  position: relative;
  transform: translateX(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.phone-number-wrapper:hover .phone-icon {
  opacity: 1;
  transform: translateX(0);
}


.call-icon {
  font-size: 24px;
  color: #ec0200;
  transform-origin: center;
}


.phone-number-wrapper:hover .call-icon {
  animation: shootArrow 0.5s forwards;
}

@keyframes shootArrow {
  0% {
    transform: translateX(0) scale(1);
  }
  50% {
    transform: translateX(10px) scale(1.2);
  }
  100% {
    transform: translateX(15px) scale(1);
  }
}
 */