/* Wrapper for the entire ad */
.category-page-bottom-ad-wrapper {
  display: flex;
  align-items: stretch; /* Ensure image and content have the same height */
  margin: 40px 0; /* Add spacing on top and bottom */
  width: 100%; /* Full width of the container */
  height: 180px; /* Set the height to 180px */
  overflow: hidden; /* Clip any overflowing content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
  background-color: #F2F2F2;
}


/* Image Section */
.category-page-bottom-ad-image-container {
  flex: 3 1; /* Image takes 30% of the available space */
  margin-right: -20px;
}

.category-page-bottom-ad-image {
  width: 100%; /* Fill the container width */
  height: 100%; /* Match the height of the content */
  object-fit: cover; /* Ensure the image fits nicely */
  object-position: bottom center;
}

/* Content Section */
.category-page-bottom-ad-content {
  flex: 7 1; /* Content takes 70% of the available space */
  background-color: #ec0200; /* Red background */
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center; /* Center the text vertically */
  padding: 20px; /* Spacing inside the content box */
  border-radius: 20px 0 0 20px;
  gap: 40px;
}

/* Text Section */
.category-page-bottom-ad-text {
  margin-bottom: 10px; /* Space between text and button */
}

.category-page-bottom-ad-main-text {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.category-page-bottom-ad-sub-text {
  font-size: 1rem;
  margin: 0;
}

/* Button */
.category-page-bottom-ad-button {
  background-color: white;
  color: #ec0200; /* Red text for contrast */
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 20px;
  padding: 10px 20px; /* Padding inside the button */
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: auto; /* Button width matches its content */
  align-self: center; /* Center-align the button vertically in the flex container */
  margin: 0 auto; /* Center-align the button horizontally */
  white-space: nowrap;
}

.category-page-bottom-ad-button:hover {
  background-color: #f5f5f5; /* Slightly darker white for hover */
}

