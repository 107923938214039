/* src/components/FullHorizontalAd.css */
.full-horizontal-ad {
  display: flex;
  align-items: center;
  background-color: #ec0200; /* Red background */
  color: white;
  padding: 40px;
  position: relative;
}

.ad-content {
  margin-left: 3%;
  max-width: 65%;
}

.ad-title {
  font-size: 3vw;
  font-weight: bold;
  margin-bottom: 20px;
}

.ad-description {
  font-size: 1.5vw;
  line-height: 1.5;
}

.full-horizontal-ad .ad-image-container {
  position: absolute;
  right: 3%;
  bottom: -4%; /* Align to the bottom of the red box */
  width: 30%; /* Fixed width for the image container */
  height: 100%; /* Adjust height based on content */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden; /* Prevent overflow */
}

.full-horizontal-ad .ad-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Maintain aspect ratio */
  overflow: visible;
  z-index: 100;
}

.full-horizontal-ad .ad-image-container .ad-image img {
  clip-path: none; /* Remove any clipping */
  mask: none; /* Remove any masking */
}
