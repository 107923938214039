/* Positioning the Cart Option */
.cart-option-container {
  position: relative; /* For dropdown positioning */
  display: inline-block;
  font-family: "acumin-variable", sans-serif;
  font-variation-settings: "slnt" 0, "wdth" 105, "wght" 500;
}

.cart-option-container,
.cart-option-container * {
  font-family: "acumin-variable", sans-serif !important;
}

/* Cart Trigger */
.cart-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ec0200;
  padding: 11px 20px;
}


/* Floating Container Styling */
.cart-option-container .floating-container {
  position: absolute;
  top: 40px; /* Place below the trigger */
  display: none; /* Hidden by default */
  z-index: 10;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px 0;
}

/* Show Dropdown on Hover */
.cart-option-container:hover .floating-container {
  display: block;
}
