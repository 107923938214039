/* Header Container */
.category-header {
  background-color: white;
  margin-bottom: 20px;
  text-align: left;
}

/* Breadcrumb Styling */
.breadcrumb {
  font-size: 1rem;
  color: #333;
  margin-bottom: 15px;
}

.breadcrumb span {
  font-weight: bold;
}

/* Hero Section */
.hero-section {
  display: flex;
  align-items: center;
  background-color: #ec0200; /* Red background */
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  height: 160px;
}

/* Hero Text */
.hero-text {
  flex: 3;
  padding: 10px;
}

.hero-text h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: white;
}

.hero-text p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: white;
}

/* Hero Image */
.hero-image {
  flex: 2;
  height: 100%;
  text-align: center;
}

.hero-image img {
  max-width: 100%;
  max-height: 100%;
}
