/* Container */
.result-sort-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  font-family: Arial, sans-serif;
}

/* Result Count */
.result-count {
  font-size: 1rem;
  color: #333;
}

/* Sort By Container */
.sort-by-container {
  position: relative;
  cursor: pointer;
  width: 250px;
}

.sort-by-label {
  background: #ec2000;
  color: #fff;
  border-radius: 5px;
  font-size: .75rem;
  left: 16px;
  padding: 2px 5px;
  position: absolute;
  top: -12px;
}

.sort-by-selected {
  border: 1px solid #ec0200;
  border-radius: 10px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ec0200;
  font-size: 1rem;
  background: #fff;
}

.dropdown-arrow {
  margin-left: 10px;
  font-size: 0.8rem;
}

/* Dropdown */
.sort-by-dropdown {
  position: absolute;
  top: 110%;
  right: 0;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  overflow: hidden;
}

.sort-by-option {
  padding: 10px 15px;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
}

.sort-by-option:hover {
  background: #f5f5f5;
}

.result-count-and-sort-down-icon {
  font-size: 0.8rem;
  color: #ec0200;
}