@import 'flag-icons/css/flag-icons.min.css';

.footer {
  background-color: #f2f2f2;
  color: #333;
  font-family: Arial, sans-serif;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Positions items on the left and right */
  align-items: center; /* Align items vertically in the center */
  gap: 30px; /* Space between items in .footer-top-left and .footer-top-right */
  font-size: 16px;
  width: 100%; /* Ensure the container takes the full width */
}

.footer-top-left,
.footer-top-right {
  display: flex; /* Use flexbox to lay out the links inside */
  align-items: center;
  gap: 30px; /* Space between links */
}

.footer-links a {
  text-decoration: none;
  color: #333;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #ec0200; /* Hover effect for links */
}

.store-selector {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

.flag-icon {
  width: 24px;
  height: 16px;
  margin-right: 8px;
}


.footer-middle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 20px;
  gap: 20px;
}

.footer-middle-left,
.footer-middle-right {
  display: flex; /* Make sure both sections are flex containers */
  align-items: center; /* Align items vertically within the sections */
}

.footer-middle-left {
  gap: 20px; /* Space between app downloads and social icons */
}

.footer-middle-right {
  gap: 20px; /* Space between the Humane Society and RP Animal Services logos */
}

.app-downloads {
  display: flex;
  gap: 15px;
  padding-right: 15px; /* Space between the icons and the border */
  position: relative;
}

.app-downloads::after {
  content: '';
  position: absolute;
  right: 0;
  top: -3px;
  height: 46px; /* Adjust the height to your liking */
  border-right: 2px solid #d8d8d8;
}


.footer-socials {
  display: flex;
  gap: 15px;
  margin-left: auto;
}

.social-icon {
  height: 36px;
  cursor: pointer;
  display: inline-flex; /* Center the icon */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.download-icon {
  height: 40px;
  cursor: pointer;
  display: inline-flex; /* Center the icon */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.footer-bottom {
  text-align: center;
  border-top: 3px solid #d8d8d8;
  padding: 5px 20px;
  font-size: 14px;
}

.footer-bottom-first-line {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distribute items with equal space */
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  gap: 10px 20px;
  margin-top: 10px;
  width: 100%; /* Ensure the container takes the full width */
}

@media (max-width: 1265px) {
  .footer-bottom-first-line {
    justify-content: center;
  }
}

.footer-bottom-first-line a {
  text-decoration: none;
  color: #333;
  transition: color 0.3s;
}

.footer-bottom-first-line a:hover {
  color: #ec0200; /* Hover effect for legal links */
}

.privacy-choices-wrapper {
  display: flex;
  align-items: center;
  gap: 4px; /* Adjust the space between the icon and the link */
}

.privacy-icon {
  color: #ec0200;
}

/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup Content */
.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  text-align: left;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.store-toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ec0200;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  margin-left: auto;
  white-space: no-wrap;
}

.humane-society-logo,
.rp-animal-services-logo,
.shoppe-logo {
  width: auto;
}

.shoppe-logo {
  height: 36px;
  width: auto;
}

.local-shopping-text {
  white-space: no-wrap;
}

.rp-city-logo {
  height: 24px;
  width: auto;
  margin-right: 5px;
}