/* src/components/CategoryCollection.css */
.collections-container {
  text-align: center;
  padding: 1px;
  background-color: #fffbf3;
  position: relative;
}

.collections-title {
  font-size: 2rem;
  margin-bottom: 15px;
}

.scroll-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.collections-items {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 35px;
  margin-top: 20px;
}

.collections-items::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.collection-item {
  min-width: 180px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: 20px;
  cursor: pointer;
}

.collection-item:first-child {
  margin-left: 30px
}

.collection-item:last-child {
  margin-right: 30px
}

.collection-icon {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placeholder-icon {
  font-size: 100px;
  color: #f77877;
}

.collection-label {
  font-size: 1.2rem;
  color: #333;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #333;
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0.4; /* Reduce opacity to make the button more transparent */
  transition: opacity 0.3s; /* Add a smooth transition effect */
}

.scroll-button:hover {
  opacity: 0.7; /* Fully opaque on hover */
}

.prev-button {
  left: 20px;
}

.next-button {
  right: 20px;
}

.scroll-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
