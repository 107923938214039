.cart-item {
  display: flex;
  align-items: center;
  padding: 15px 20px 5px 20px;
  border-bottom: 1px solid #e3e3e3;
  transition: background-color 0.3s;
  cursor: pointer;
}

.cart-item:first-child {
  margin-top: 5px;
}

.cart-item:hover {
  background-color: #e3e3e3;
}

.cart-dropdown .cart-item-image {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 8px;
  margin-right: 10px;
}

.cart-item-placeholder {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #f77877;
  background-color: #fff;
  border-radius: 8px;
}

.cart-item-details {
  flex: 1;
  text-align: left;
}

.cart-item-title {
  font-size: 14px;
  font-variation-settings: "slnt" 0, "wdth" 105, "wght" 700;
  margin: 0 0 5px;
}

.cart-item-price {
  font-size: 13px;
  font-variation-settings: "slnt" 0, "wdth" 105, "wght" 600;
  margin: 0;
}

.cart-item-quantity {
  font-variation-settings: "slnt" 0, "wdth" 105, "wght" 400;
  color: #666;
}


.cart-dropdown .cart-subtotal {
  padding: 10px 20px 5px 20px;
  border-bottom: 1px solid #c0c0c0;
  font-variation-settings: "slnt" 0, "wdth" 105, "wght" 600;
  font-size: 20px;
}

.cart-dropdown .cart-shipping {
  padding: 10px 0 5px 0;
  margin: 0 22px 5px 22px;
  border-bottom: 2px solid #a60000;
  font-variation-settings: "slnt" 0, "wdth" 105, "wght" 500;
  font-size: 14px;
}

/* Parent container for buttons */
.cart-dropdown-buttons {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center; /* Center the buttons horizontally */
  text-align: center;
  gap: 10px; /* Space between buttons */
  padding: 10px 20px 0 20px;
}

/* Sticky Checkout Button */
.cart-dropdown .cart-dropdown-buttons {
  position: sticky;
  top: 0; /* Sticks to the top of its scrollable container */
  background-color: white; /* Matches the container background to avoid visual overlap */
  z-index: 10; /* Ensures it stays above the content */
}


/* Buttons should have the same width */
.cart-dropdown-buttons button {
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  padding: 13px;
  border-radius: 35px;
  box-sizing: border-box;
  font-variation-settings: "slnt" 0, "wdth" 105, "wght" 600;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.1s ease-in-out;
}

/* View Cart button styles */
.cart-dropdown .view-cart-button {
  color: #ec0200;
  background-color: white;
  border: 2px solid #ec0200;
  padding: 11px 20px;
}

/* Proceed to Checkout button styles */
.cart-dropdown .checkout-button {
  color: white;
  background-color: #ec0200;
  border: none;
  padding: 13px 20px;
}


.cart-dropdown .checkout-button:hover {
  background-color: #bd0201;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Elevated shadow for 3D effect */
}

.cart-dropdown .checkout-button:active {
  transform: translateY(2px); /* Button appears "pressed" */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduced shadow for pressed effect */
}

.cart-dropdown .view-cart-button:hover {
  background-color: #f5f5f5; /* Light background on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Elevated shadow for 3D effect */
}

.cart-dropdown .view-cart-button:active {
  transform: translateY(2px); /* Button appears "pressed" */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduced shadow for pressed effect */
}

