/* MenuBar.css */
.menu-bar {
  background-color: #3a3a3a; /* Dark background color */
  color: white; /* White text color */
  padding: 1.5vh 20px;
}

.menu-list {
  display: flex;
  justify-content: flex-start; /* Space out the menu items evenly */
  gap: 3vw;
  list-style: none; /* Remove default list styling */
  margin: 0;
  padding: 0;
}

.menu-bar .menu-item {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  position: relative; /* Needed for dropdown menus */
  display: flex;
  align-items: center;
}


.menu-bar .menu-item:hover {
  color: #ec0200;
}

.menu-bar .menu-item .arrow-icon {
  stroke: white; /* White color for the arrow */
  margin-left: 3px; /* Space between text and arrow */
  margin-top: 4px;
}

.menu-bar .menu-item:hover .arrow-icon {
  stroke: #ec0200;
}

.dropdown {
  display: none; /* Hide by default */
  position: absolute;
  top: 100%; /* Position the dropdown below the menu item */
  left: 0;
  background-color: #444; /* Darker background for the dropdown */
  color: white;
  padding: 0;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add a subtle shadow */
  min-width: 150px; /* Minimum width for the dropdown */
  z-index: 100; /* Make sure it appears above other elements */
}

.contains-dropdown:hover .dropdown {
  display: block; /* Show the dropdown on hover */
}

.dropdown p {
    padding: 10px;
    margin: 0;
    cursor: pointer;
    transition: background-color 0.2s;
}

.dropdown p:first-child {
  margin-top: 10px; /* Add 10px margin on top of the first child */
}

.dropdown p:last-child {
  margin-bottom: 10px; /* Add 10px margin on bottom of the last child */
}

.dropdown p:hover {
    background-color: white;
    color: #ec0200;
}
