/* src/components/SmallHorizontalAd.css */

.small-horizontal-ad-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fffbf3;
  padding: 40px;
  color: white;
  overflow: hidden;
  position: relative;
}

.small-horizontal-ad {
  display: flex;
  align-items: flex-end; /* Center items vertically */
  background-color: #ec0200; /* Red background */
  color: white;
  height: 80%;
  width: 80%;
  position: relative;
  overflow: hidden; /* Ensure no content overflows */
}

.image-section {
  width: 35%; /* Adjust the width as needed */
  height: 100%; /* Make the image section fill the height */
  display: flex; /* Use flexbox to center the image */
  align-items: flex-end; /* Anchor the image to the bottom */
  justify-content: center; /* Center the image horizontally */
}

.small-horizontal-ad-wrapper .ad-image {
  width: 100%; /* Maintain the aspect ratio */
  height: auto; /* Make the image fill the height */
  object-fit: contain; /* Ensure the entire image is shown without cropping */
}

/* Text section styling */
.text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center text vertically */
  align-items: center; /* Center text horizontally */
  padding: 20px;
  text-align: center; /* Center align text */
}

.ad-subtitle {
  font-size: 2.3vw;
  margin: 0;
  font-weight: normal;
  white-space: no-wrap;
}

.ad-title {
  font-size: 2.8vw;
  margin: 10px 0;
  font-weight: bold;
  white-space: no-wrap;
}

.shop-now-button {
  background-color: white;
  color: #e3342f;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1.3vw;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  margin-top: 10px; /* Add some space above the button */
  margin-bottom: 1.5vw;
}

.shop-now-button:hover {
  background-color: #f5f5f5;
}
