/* TopBar.css */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ec0200; /* Red background */
  color: white;
  padding: 5px 0;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  gap: 10px;
}

.top-bar-text-container {
  flex-grow: 1; /* Take up the remaining space */
  overflow: hidden; /* Hide the overflowing text */
  position: relative;
}

.top-bar-text {
  display: inline-block;
  white-space: nowrap;
  font-style: italic;
  animation: scrollText linear infinite;
  /* Adjust the animation speed using the speed prop */
}

@keyframes scrollText {
  from {
    transform: translateX(var(--animation-distance)); /* Start just outside the container */
  }
  to {
    transform: translateX(-100%); /* Move fully off the left side */
  }
}

.top-bar-menu {
  display: flex;
  gap: 10px; /* Space between menu items */
  margin-right: 10px;
  white-space: nowrap;
}

/* TopBar.css */
.top-bar-menu .menu-item {
  color: white;
  text-decoration: none;
  position: relative; /* Needed for the pseudo-elements */
  transition: background-color 0.3s, text-shadow 0.3s;
  padding: 3px 8px;
  border-radius: 5px;
}

.top-bar-menu .menu-item:hover {
  background-color: #f34545;
}
