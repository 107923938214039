/* Overall Container */
.category-page-selector {
  width: 100%;
  padding-bottom: 20px;
  border-top: 1.5px solid #ddd;
}

/* Title */
.category-page-selector-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333;
}

/* Search Container */
.category-page-selector-search-container {
  position: relative;
  margin-bottom: 15px;
}

.category-page-selector-search-input {
  width: 100%;
  padding: 10px 40px 10px 10px; /* Add padding for the search icon */
  border: 1px solid #ccc;
  border-radius: 8px; /* Rounded corners */
  font-size: 1rem;
  box-sizing: border-box; /* Include padding in the width */
}

.category-page-selector-search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #aaa;
  cursor: pointer;
}

/* Category List */
.category-page-selector-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.category-page-selector-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 1rem;
  color: #333;
}

.category-page-selector-item input[type="checkbox"] {
  margin-right: 10px;
}

/* Toggle Button */
.category-page-selector-toggle-button {
  display: inline-block;
  margin-top: 10px;
  font-size: 1rem;
  color: #007bff;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.category-page-selector-toggle-button:hover {
  text-decoration: underline;
}
