/* Featured Blogs Container */
.featured-blogs-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Three equal columns */
  gap: 20px;
  padding: 30px;
}

.featured-blogs-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ededed;
}


.featured-blogs-wrapper .collection-title {
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 30px;
  text-align: center;
}

/* Individual Blog Columns */
.blogs-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 400px;
}

.blogs-column.large {
  flex: 1;
}

.blogs-column.medium {
  flex: 1;
}

.blogs-column.small {
  flex: 1;
}

/* Blog Card Styling */
.blog-card {
  display: flex;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}

.blog-card.large {
  height: 100%;
  flex-direction: column;
}

.blog-card.medium, .blog-card.small {
  flex-direction: row;
}

.blog-card.medium {
  height: 50%;
}

.blog-card.small {
  height: 33.33%;
}
.blog-card.large .blog-image, .blog-card.medium .blog-image, .blog-card.small .blog-image {
  align-items: center;
  background-color: #ddd;
  display: flex;
  justify-content: center;
}
.blog-card.large .blog-image {
  height: 190px;
}
.blog-card.medium .blog-image, .blog-card.small .blog-image {
  width: 40%;
}

.blog-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder-icon {
  font-size: 5rem;
  color: #ec0200;
  opacity: 0.6;
}

/* Blog Info */
.blog-info {
  padding: 15px;
  flex: 1;
}

.blog-card.large .blog-info {
  padding: 15px;
}

.blog-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.blog-title.large-title {
  font-size: 1.5rem;
}

.blog-description {
  font-size: 0.9rem;
  color: #555;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
  text-align: justify;
}

.blog-card.large .blog-description {
  -webkit-line-clamp: 6;
}

.blog-card.medium .blog-description {
  -webkit-line-clamp: 6;
}

.blog-card.small .blog-description {
  -webkit-line-clamp: 2;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .featured-blogs-container {
    grid-template-columns: 1fr;
  }

  .blog-card {
    height: auto;
  }
}
