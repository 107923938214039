/* Product Card Container */
.category-page-product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 12px;
  background-color: #fff;
  overflow: hidden;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.category-page-product-card-image {
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  margin-bottom: 15px;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
}


/* Product Details */
.category-page-product-card-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
}

.category-page-product-card-name {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.category-page-product-card-brand {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

/* Rating */
.category-page-product-card-rating {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
  color: #333;
}

.rating-stars {
  color: #ffb400;
}

.rating-score {
  font-weight: bold;
}

.rating-reviews {
  color: #666;
}

/* Availability */
.category-page-product-card-availability {
  font-size: 0.9rem;
  color: #333;
}

.availability-label {
  font-weight: bold;
  color: #000;
}

.category-page-product-card-availability p {
  margin: 5px 0;
}

/* Price */
.category-page-product-card-price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #000;
  margin: 10px 0;
}

/* Actions */
.category-page-product-card-actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.category-page-quantity-controls {
  display: flex;
  align-items: center;
  border: 1px solid #ec0200;
  border-radius: 8px;
  overflow: hidden;
}

.category-page-quantity-button {
  background-color: #fff;
  cursor: pointer;
  font-size: 1rem;
  border: none;
  padding: 8px 10px;
}

.category-page-quantity-button:hover {
  background-color: #f0f0f0;
}

/* Quantity Input Field */
.category-page-quantity-value {
  width: auto;
  min-width: 20px;
  max-width: 40px;
  text-align: center;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  background-color: #fff;
  outline: none;
  -moz-appearance: textfield; /* Remove number input spin buttons for Firefox */
}

.category-page-quantity-value::-webkit-outer-spin-button,
.category-page-quantity-value::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Remove number input spin buttons for Webkit */
  margin: 0;
}


.category-page-product-card-add-to-cart-button {
  padding: 10px 15px;
  background-color: #ec0200;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
}

.category-page-product-card-add-to-cart-button:hover {
  background-color: #d32f2f;
}

.availability-info {
  color: #ec0200;
}

.category-page-product-card-image-content {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.category-page-product-card-image-placeholder {
  width: 100%;
  height: 100%;
  background-color: #ddd;
}