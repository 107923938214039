.boxiecat-card {
    display: flex; /* Use flexbox to align children horizontally */
    border: 10px solid #700000; /* 10px border with specified color */
    border-radius: 0; /* No rounding */
    box-sizing: border-box; /* Include padding and border in size */
    background-color: #fff3e1;
    position: relative;
    gap: 30px;
}

.boxiecat-feature-left {
  padding: 10px 20px 0 50px;
  flex: 0.3;
}

.boxiecat-feature-center {
  padding: 20px 0;
  flex:0.5;
}

.boxiecat-feature-right {
  flex: 0.2; /* Take 20% of the width */
  position: relative; /* Position relative to allow absolute positioning of the cat image */
  padding: 0; /* Remove inherited padding */
  display: block; /* Detach from `align-items: center` logic */
}

.boxiecat-feature-right .boxiecat-upsidedown-cat {
  height: 220px;
  margin: 0;
  position: absolute;
  right: 24%;
  top: 0;
}



.boxiecat-feature-attributes-wrapper {
    position: absolute;
    background-color: transparent;
    top: 400px;
    padding-left: 60px;
}

.boxiecat-feature-attribute.product-name {
    background-color: transparent;
    color: #700102;
    padding: 0 0 10px 0;
    margin: 0;
}

.no-bold {
    font-weight: normal;
}

.boxiecat-feature-attribute.product-price {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 20px;
    color: #700102;
}

.boxiecat-feature-add-to-cart-wrapper button {
    border-radius: 10px;
    box-sizing: border-box;
    font-size: 16px;
}

.boxiecat-feature-quantity {
    padding: 8px 10px;
    color: #ec0200;
    border: 2px solid #ec0200;
    background-color: white;
}


.boxiecat-feature-quantity .quantity-decrease {
    margin-right: 25px;
}

.boxiecat-feature-quantity .quantity-increase {
    margin-left: 25px;
}

.boxiecat-feature-quantity .quantity-select {
    width: 20px;
}

.boxiecat-feature-quantity span {
    cursor: pointer;
}

.boxiecat-feature-cart {
    color: white;
    background-color: #ec0200;
    border: none;
    margin-left: 20px;
    padding: 10px 20px;
    cursor: pointer;
}

.boxiecat-badge-container {
    top: 270px;
    position: absolute;
    right: 8%;
    text-align: center;
}

.boxiecat-badge-container .boxiecat-badge {
    height: 150px;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: white;
}

.boxiecat-forbes {
    font-family: "minion-std-black", serif;
    font-weight: 400;
    font-style: normal;
    color: #043164;
    font-size: 40px;
}

.boxiecat-as-seen-in {
    font-family: "acumin-variable",sans-serif;
    font-variation-settings: 'wght' 600, 'wdth' 80, 'slnt' 0;
    color: black;
    margin-top: 20px;
    font-size: 16px;
}