/* General Styles */
.boxiecat-container {
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #fff3e1;
  max-width: 590px;
  box-sizing: border-box;
}

/* Header Section */
.boxiecat-header h1 {
  font-size: 35px;
  padding-bottom: 5px;
  font-weight: bold;
  text-align: left;
  margin: 0;
  color: #700102;
  display: inline-block;
  border: none;
  border-bottom: 2px dotted #ec0200;
}

.boxiecat-highlight {
  color: #ec0200;
}


/* NO Section */
.boxiecat-no-section {
  display: flex;
  margin-top: 0;
  gap: 20%;
}

.boxiecat-no-item {
  text-align: center;
}

.boxiecat-no {
  font-family: "Arial Black", sans-serif;
  font-weight: bold;
  font-size: 68px;
  color: #ec0200;
  margin: 0;
}

.boxiecat-no-description {
  font-family: "minion-pro", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  margin-top: 0;
  color: #700102;
}

/* How Probiotics Work Section */
.boxiecat-how-probiotics {
  margin: 0;
}

.boxiecat-how-probiotics h3 {
  font-size: 26px;
  font-weight: bold;
  text-align: left;
  color: #700102;
  margin-top: 20px;
  margin-bottom: 10px;
}

.boxiecat-steps {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin: 0;
  text-align: left;
}

.boxiecat-step {
  flex: 1;
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack child elements vertically */
  align-items: center; /* Center the image horizontally */
  text-align: left;
  background-color: #ffffff;
  border: 1.5px solid #2db8e5;
  border-radius: 8px;
  padding: 5px;
  box-sizing: border-box;
  max-width: 140px;
}

.boxiecat-step-image {
  margin: 5px;
}

.boxiecat-step-image.odor-icon {
  width: 100px;
}

.boxiecat-step-image.cat-icon {
  height: 50px;
}

.boxiecat-step-image.clean-icon {
  height: 45px;
}
.boxiecat-step-image.probiotic-icon {
  height: 50px;
}

.boxiecat-step-text {
  display: flex; /* Wrapper for text content */
  flex-direction: column; /* Stack heading and paragraph */
  align-items: flex-start; /* Left-align text content */
  width: 100%; /* Ensure the text takes up the full width */
}

.boxiecat-step h4 {
  font-size: 14px;
  color: #333;
  margin: 0;
  text-align: left;
  text-decoration: underline;
}

.boxiecat-step p {
  font-size: 10px;
  color: #555;
  margin: 0;
  text-align: left; /* Ensure text aligns left */
  width: 100%; /* Prevent text from breaking alignment */
}



.boxiecat-faqs {
  max-width: 590px;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.boxiecat-faq-section {
  margin: 0;
  padding: 0;
}

.boxiecat-faqs .faq-item {
  padding: 0;
  background-color: transparent;
}

.boxiecat-faqs .faq-question {
  font-size: 14px;
}

.boxiecat-faqs .faq-question h4{
  margin-top: 10px;
  margin-bottom: 10px;
}

.boxiecat-faqs .faq-answer {
  padding: 0;
  background-color: transparent;
}

.faq-answer-list {
  margin: 0;
  padding-left: 10px;
}