/* Container styling */
.newsletter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ec0200; /* Red background */
  color: white;
  position: relative;
  overflow: hidden;
}

/* Background World Map SVG */
.world-map-background {
    right: -3%;
    top: -35%;
    width: 44%;
    height: auto;
    opacity: .5;
    position: absolute;
    z-index: 0;
}

/* Left Section: Animated Letter */
.newsletter-left {
  flex: 0 0 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1; /* Ensures it's above the background */
}

/* Right Section: Content */
.newsletter-right {
  flex: 1;
  text-align: left;
  padding-left: 30px;
  position: relative;
  z-index: 1; /* Ensures content is above the background */
}

.newsletter-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
}

.newsletter-description {
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 0;
}

.newsletter-form {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  width: 80%;
}

.newsletter-input {
  flex: 1;
  padding: 10px;
  border: 2px solid white;
  border-radius: 5px;
  background-color: transparent;
  color: white;
  font-size: 14px;
  outline: none;
}

.newsletter-input::placeholder {
  color: #ddd;
}

.subscribe-button {
  background-color: white;
  color: #ec0200;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.subscribe-button:hover {
  background-color: #ddd;
  color: #ec0200;
}

.newsletter-agreement {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.newsletter-agreement input[type="checkbox"] {
  margin-right: 8px;
  cursor: pointer;
}
