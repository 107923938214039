/* Wrapper for the banner */
.master-category-banner {
  position: relative;
  width: 100%;
  height: 280px; /* Adjust height */
  background-size: cover; /* Ensure the background covers the container */
  background-position: center right; /* Center the background */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: visible;
}

/* Content Section (Product and Description) */
.master-category-banner-content {
  align-items: center;
  display: flex;
  flex: 1 1;
  gap: 20px;
  padding: 20px;
  margin-bottom: -4%;
  margin-left: 4%;
  z-index: 1;
}

/* Product Image */
.master-category-banner-product-image {
  height: 250px;
  object-fit: contain;
  top: 12%;
  left: 6%;
}

/* Text Section */
.master-category-banner-text {
  color: #2d2d2d;
  padding: 10px;
}

.master-category-banner-title {
  font-size: 1.8rem;
  margin: 0 0 10px;
  font-family: "myriad-pro", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #183157;
}

.master-category-banner-benefits {
  list-style: none;
  padding: 0;
  margin: 0;
}

.master-category-banner-benefits li {
  font-size: 1.1rem;
  font-family: "myriad-pro", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #183157;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

/* Footer Section */
.master-category-banner-footer {
  background-color: #EAB72A;
  padding: 0 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 50px;
}

.master-category-banner-footer-text {
  font-size: 1rem;
  font-weight: bold;
  color: #2d2d2d;
  display: flex;
  align-items: center;
}

.master-category-banner-footer-icon {
  height: 24px;
  margin-right: 10px;
}

.master-category-dental-icon {
  height: 100%;
  width: auto;
}

.master-category-banner-title-logo {
  margin-top: -80px;
  width: 230px;
}

.proden-plaqueoff-tick-mark {
  height: 20px;
  width: auto;
  margin-right: 20px;
}

.master-category-banner-wrapper .slideshow-pagination-bar-wrapper{
  background-color: transparent;
}

.master-category-banner-wrapper .slideshow-pagination-bar-container{
  background-color: transparent;
}

.master-category-banner-wrapper .slideshow-pagination-bar-dot {
  background-color: #000; /* Default dot color */
}

.master-category-banner-wrapper .slideshow-pagination-bar-dot.active {
  background-color: #EAB72A; /* Active dot color (white) */
}

.master-category-banner-wrapper .slideshow-pagination-bar-dot:hover {
  background-color: #bbb; /* Hover color for dots */
}

.master-category-banner-wrapper .slideshow-pagination-bar-pause-play-button {
  color: #000;
  margin-right: 0;
}