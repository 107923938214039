/* Main container for the category section */
.category-page-category-section {
  width: 100%;
  font-family: Arial, sans-serif;
  padding-bottom: 20px;
}

.category-page-category-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #000;
}

.category-page-category-list {
  list-style: none;
  padding: 0;
  margin: 0;
}


.category-page-category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 5px 0;
}

.category-page-category-name {
  font-size: 1rem;
  color: #2E3192; /* Blue text */
  text-decoration: none;
}

.category-page-icon {
  font-size: 0.8rem;
  color: #666;
}

.category-page-category-dropdown {
  margin-top: 5px;
  padding-left: 15px;
}

.category-page-category-dropdown ul {
  list-style: none;
  padding: 0;
}

.category-page-category-dropdown li {
  font-size: 0.9rem;
  color: #555;
}
