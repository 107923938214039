.homepage-about-us-section {
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center;
  background-color: #fff;
}

.homepage-about-us-background-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.homepage-about-us-content {
    color: #fff;
    left: 6%;
    max-width: 500px;
    position: absolute;
    top: 11%;
    z-index: 2;
}

.homepage-about-us-content h2 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 0;
    text-align: left;
}

.homepage-about-us-content p {
    font-size: 1.1rem;
    line-height: 1.2;
    text-align: justify;
    margin-top: 3px;
}

.homepage-about-us-wave-pattern {
    background: url("https://cdn.thelittlepetshoppe.com/wave-pattern-homepage-about-us-section.svg") no-repeat;
    background-size: cover;
    bottom: 23.6%;
    height: 120px;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.homepage-about-us-features {
    display: flex;
    gap: 12%;
    height: auto;
    justify-content: center;
    margin-top: -147px;
    position: relative;
    z-index: 2;
    margin-bottom: 50px;
}

.homepage-about-us-feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  width: 180px;
}

.homepage-about-us-icon {
  background-color: #f10000;
  color: white;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem; /* Adjust icon size */
  margin-bottom: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.homepage-about-us-feature-content {
  margin-top: 10px;
}

.homepage-about-us-feature-content p {
  font-size: 1rem;
  margin: 10px 0;
}

.homepage-about-us-learn-more-btn {
  padding: 8px 12px;
  background-color: #f10000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

.homepage-about-us-learn-more-btn:hover {
  background-color: #fd6463;
}

@media (max-width: 768px) {
  .homepage-about-us-features {
    flex-wrap: wrap;
    gap: 50px;
  }

  .homepage-about-us-feature-item {
    width: 120px;
  }

  .homepage-about-us-icon {
    width: 100px;
    height: 100px;
    font-size: 2rem;
  }
}