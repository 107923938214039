/* src/components/ProductCollection.css */
.new-arrivals-wrapper {
  padding: 1px;
  background-color: #fffbf3;
  position: relative;
}

.new-arrivals-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 30px;
}

.new-arrivals-product-collection {
  display: flex;
  overflow-x: scroll;
  gap: 15px;
  padding-bottom: 20px;
}

.new-arrivals-product-collection::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.new-arrivals-product-card {
  position: relative; /* Ensure badge is positioned relative to this container */
  background-color: transparent;
  width: 200px;
  flex-shrink: 0;
  text-align: center;
  margin-right: 20px;
}

/* Increase margin-right for the first child */
.new-arrivals-product-card:first-child {
  margin-left: 30px;
}

.new-arrivals-product-card:first-child .new-arrivals-product-image {
  border-radius: 15%;
}

/* Add margin-left for the last child */
.new-arrivals-product-card:last-child {
  margin-right: 30px;
}

/* Styles for the visible box */
.new-arrivals-product-visible-box {
  background-color: #efefef;
  border-radius: 10px 10px 0 0;
  padding: 10px;
  margin-bottom: 10px; /* Space between the box and the product details */
}

.new-arrivals-product-image {
  align-items: center;
  background-color: #d6d6d6;
  display: flex;
  height: 250px;
  width: 200px;
  justify-content: center;
  margin-bottom: 5px;
  border: 2px solid #7c7c7c;
  box-sizing: border-box;
  border-radius: 50% / 35%;
}

.new-arrivals-product-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.new-arrivals-product-name {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 10px;
}

/* Product details section */
.new-arrivals-product-details {
  text-align: center;
}

.new-arrivals-brand-name {
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 5px;
}

.new-arrivals-product-description {
  font-size: 12px;
  color: #666;
  margin: 0 0 10px;
}

.new-arrivals-price-section {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.new-arrivals-quantity-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.new-arrivals-quantity-controls button {
  background: transparent;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.new-arrivals-quantity {
  width: 50px;
  text-align: center;
  border: 1px solid #ddd;
  margin: 0 5px;
  padding: 5px;
  border-radius: 5px;
}

.new-arrivals-add-to-cart-button {
  background-color: #ec0200;
  color: white;
  border: none;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s;
}

.new-arrivals-add-to-cart-button:hover {
  background-color: #bf0000;
}

.new-arrivals-wrapper .scroll-button {
  position: absolute;
  top: 200px;
  transform: translateY(-50%);
  background-color: #ec0200;
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  opacity: 0.3;
  transition: opacity 0.3s;
}

.new-arrivals-wrapper .scroll-button:hover {
  opacity: 0.6;
}

.new-arrivals-wrapper .scroll-button.left {
  left: 20px;
}

.new-arrivals-wrapper .scroll-button.right {
  right: 20px;
}

.new-arrivals-hidden-controls {
  display: none; /* Hide controls by default */
  margin-top: 10px;
}

.new-arrivals-product-card:hover .new-arrivals-hidden-controls {
  display: flex; /* Show controls on hover */
  flex-direction: column;
  align-items: center;
}

.new-arrivals-buy-now-button {
  background-color: #079000;
  color: white;
  border: none;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s;
  margin-top: 5px;
}

.new-arrivals-buy-now-button:hover {
  background-color: #056500;
}

.new-arrivals-badge {
  height: 42px;
  width: 42px;
  position: absolute;
  right: 6%;
  top: 0;
}

.new-arrivals-product-card:first-child .new-arrivals-badge {
  right: -4%;
}
