.pagination-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: Arial, sans-serif;
}

.pagination-pages {
  display: flex;
  align-items: center;
  gap: 8px;
}

.pagination-button {
  padding: 8px 15px;
  border: none;
  border-radius: 20px;
  background-color: white;
  color: #ec0200;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.pagination-button:hover {
  background-color: #D6D6D6;
}

.pagination-button.active {
  background-color: #ec0200;
  color: white;
}

.pagination-button.disabled {
  background-color: #f5f5f5;
  color: #ccc;
  cursor: not-allowed;
}

.pagination-ellipsis {
  font-size: 14px;
  color: #999;
}

.pagination-bar .previous-button,
.pagination-bar .next-button {
  font-size: 14px;
  border: 2px solid #AFAFAF;
  padding: 8px 25px;
}

.pagination-bar .previous-button {
  margin-right: 25px;
}

.pagination-bar .next-button {
  margin-left: 25px;
}

.pagination-bar .previous-button.disabled,
.pagination-bar .next-button.disabled {
  cursor: not-allowed;
}
