.brand-collection-wrapper {
  text-align: center;
  background-color: #fff;
  padding-top: 20px;
  overflow: hidden; /* Ensure content stays within the wrapper */
}

.scroll-container {
  position: relative;
  display: flex;
  align-items: center;
}

.brand-collection {
  display: flex;
  overflow-x: scroll;
  padding: 10px 0;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.brand-collection::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.brand-collection-item {
  flex: 0 0 auto;
  width: 260px;
  text-align: center;
  background-color: #fff;
  padding: 10px;
  margin-left: 30px;
}

.brand-collection-item:first-child {
  margin-left: 30px;
}

.brand-collection-item:last-child {
  margin-right: 30px;
}

.brand-collection-image-container {
  height: 200px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid #dddddd;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.brand-collection-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.brand-collection-name {
  margin-top: 10px;
  font-size: 16px;
}

.scroll-button {
  background-color: #ec0200;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
  transition: opacity 0.3s;
}

.scroll-button:hover {
  opacity: 0.8;
}

.scroll-button.left {
  left: 20px;
}

.scroll-button.right {
  right: 20px;
}
