.pet-collections-wrapper {
  text-align: center;
  background-color: #fff;
  padding-top: 20px;
}

.collection-title {
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.scroll-container {
  position: relative;
  display: flex;
  align-items: center;
}

.pet-collections {
  display: flex;
  overflow-x: scroll;
  padding: 10px 0;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  margin-bottom: 20px;
}

.pet-collections::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.pet-collection-item {
  flex: 0 0 auto;
  width: 290px;
  text-align: center;
  overflow: hidden;
  background-color: #fff;
  padding: 10px;
  margin-left: 30px;
  cursor: pointer;
}

.pet-collection-item:first-child {
  margin-left: 30px;
}

.pet-collection-item:last-child {
  margin-right: 30px;
}

.pet-collection-image-container {
  height: 300px;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #dddddd; /* Border around the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow around the container */
  background-color: white; /* White background inside the container */
}

.pet-collection-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Maintain aspect ratio and fit within the container */
}

.pet-collection-name {
  margin-top: 10px;
  font-size: 16px;
}

.pet-collections-wrapper .scroll-button {
  background-color: #ec0200;
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.pet-collections-wrapper .scroll-button:hover {
  opacity: 0.8;
}

.pet-collections-wrapper .scroll-button.left {
  left: 20px;
}

.pet-collections-wrapper .scroll-button.right {
  right: 20px;
}
