.page-bottom-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #9f9e9e;
  color: white;
  padding: 10px 15px;
  font-family: Arial, sans-serif;
  width: 100%;
  box-sizing: border-box;
}

.bottom-bar-text {
  font-size: 16px;
  margin: 0;
  flex-shrink: 0;
  margin-left: 4vw; /* Add 4vw left margin */
}

.contact-item {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  flex-shrink: 0;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-item:hover {
  background-color: #f34545;
}

.icon {
  font-size: 18px;
}

.back-to-top-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: white;
  color: #9f9e9e;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  flex-shrink: 0;
  margin-right: 4vw; /* Add 4vw right margin */
}

.back-to-top-button:hover {
  background-color: #f5f5f5;
}
