/* Container for the whole section */
.pet-services {
  text-align: center;
  padding: 40px;
  background-color: #ffffff;
}

.pet-services-title {
  font-weight: bold;
  margin-bottom: 20px;
  color: #ec0200;
  font-size: 32px;
  text-align: center;
}

/* Flexbox layout for cards */
.pet-services-cards {
  display: flex;
  gap: 20px; /* Space between cards */
  justify-content: center;
  flex-wrap: wrap; /* Ensures responsiveness */
}

/* Individual card styles */
.pet-service-card {
  background-color: transparent;
  width: 29%;
  padding: 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.pet-service-card:hover {
  transform: translateY(-5px); /* Subtle hover effect */
}

/* Image Wrapper for hover */
.pet-services-image-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  cursor: pointer;
}

/* Default Image */
.pet-service-image {
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: block;
}

/* Hover Overlay (Initially Hidden) */
.pet-services-card-hover {
  position: absolute;
  top: 100%; /* Position off-screen initially */
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ec2000;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  transition: top 0.4s ease; /* Smooth slide-up effect */
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Slide Up Effect on Hover */
.pet-services-image-wrapper:hover .pet-services-card-hover {
  top: 0; /* Slide into view */
}

.pet-services-hover-icon {
  width: 30px;
  margin-left: 10px;
}

/* Hover Content Styling */
.pet-services-hover-title {
  font-family: "superior-title", serif;
  font-weight: 700;
  font-style: normal;
  font-size: 30px;
  margin: 0;
}

.pet-services-card-hover h3 {
  margin: 0;
}

.pet-services-card-hover p {
  margin: 0;
}

.pet-services-hover-subtitle {
  font-family: "acumin-variable",sans-serif;
  font-variation-settings: 'wght' 700, 'wdth' 90, 'slnt' 0;
  font-size: 20px;
  margin-top: 10px;
}


/* Default Title and Description Below the Card */
.pet-service-title {
  font-size: 18px;
  font-weight: bold;
  color: #222;
  margin: 10px 0 5px;
}

.pet-service-description {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
}
