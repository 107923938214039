/* src/components/WhyShopWithUs.css */
.why-shop-section {
  text-align: center;
  background-color: #f2f2f2; /* Light background color */
  padding: 30px 20px;
}

.section-title {
  font-size: 2.5em;
  font-weight: bold;
  color: #ec0200; /* Red color for the title */
  margin-bottom: 30px;
}

.reasons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px; /* Space between reason boxes */
}

.reason-box {
  flex: 1;
  text-align: center;
  padding: 20px;
  border-right: 4px solid #ec0200; /* Red vertical line on the right */
}

.reason-box:first-child {
  border-left: none; /* Remove border from the first box */
}

.reason-box:last-child {
  border-right: none; /* Remove border from the last box */
}

.icon-circle {
  width: 80px;
  height: 80px;
  background-color: #ec0200; /* Red background */
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  margin: 0 auto 20px;
}

.description {
  font-size: 1em;
  color: #333; /* Darker color for the description */
  line-height: 1.5;
}
