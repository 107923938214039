/* Container for the sign-in dropdown */
.sign-in-container {
  position: relative; /* To position the dropdown correctly */
  display: inline-block; /* Allow hover functionality */
  font-family: "acumin-variable", sans-serif;
  font-variation-settings: "slnt" 0, "wdth" 105, "wght" 500;
}

/* Triggering div (Sign-in option) */
.sign-in-option {
  display: flex;
  align-items: center;
  color: #ec0200;
  margin-left: 20px;
  cursor: pointer;
  padding: 11px 0;
}


.sign-in-option .icon {
  margin-right: 5px;
  font-size: 20px;
}

/* Floating container customization */
.sign-in-container .floating-container {
  position: absolute;
  top: 40px;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: none;
  z-index: 10;
}


/* Show dropdown on hover */
.sign-in-container:hover .floating-container {
  display: block;
}

/* Content inside the dropdown */
.sign-in-container .floating-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.sign-in-container .floating-content button {
  width: 100%;
  padding: 13px;
  border-radius: 35px;
  cursor: pointer;
  box-sizing: border-box;
}

.sign-in-container .floating-content .sign-in,
.sign-in-container .floating-content .register-account {
  background-color: #ec0200;
  color: white;
  border: none;
  font-family: "acumin-variable", sans-serif;
  text-align: center;
  font-variation-settings: "slnt" 0, "wdth" 105, "wght" 600;
  font-size: 14px;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.1s ease-in-out;
}

.sign-in-container .floating-content .sign-in:hover,
.sign-in-container .floating-content .register-account:hover {
  background-color: #bd0201;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Elevated shadow for 3D effect */
}

.sign-in-container .floating-content .sign-in:active,
.sign-in-container .floating-content .register-account:active {
  transform: translateY(2px); /* Button appears "pressed" */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduced shadow for pressed effect */
}


.sign-in-container .floating-content .create-account {
  background-color: transparent;
  border: 2.5px solid #ec0200;
  color: black;
  font-family: "acumin-variable", sans-serif;
  text-align: center;
  font-variation-settings: "slnt" 0, "wdth" 105, "wght" 600;
  font-size: 14px;
  box-sizing: border-box;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.1s ease-in-out;
  padding: 11px 13px;
}

.sign-in-container .floating-content .create-account:hover {
  background-color: #f5f5f5; /* Light background on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Elevated shadow for 3D effect */
}

.sign-in-container .floating-content .create-account:active {
  transform: translateY(2px); /* Button appears "pressed" */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduced shadow for pressed effect */
}

.sign-in-container .sign-in-container-text {
  color: black;
  text-align: center;
  font-family: "acumin-variable", sans-serif;
  font-variation-settings: "slnt" 0, "wdth" 105, "wght" 600;
  font-size: 14px;
  margin-top: 5px;
}


