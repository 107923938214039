.floating-container {
  position: relative;
  top: 5px;
  background-color: white;
  border: 1px solid #ccc; /* Match the triangle's border color */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  overflow: visible; /* Keep triangle visible */
}


.triangle {
  position: absolute;
  top: -16px; /* Adjust triangle placement above the container */
  left: 80%; /* Default position, can be adjusted */
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 16px solid #ccc; /* Match the container's border */
  transition: all 0.3s ease; /* Smooth movement */
  z-index: 2;
}

.triangle:after {
  content: '';
  position: absolute;
  top: 1px; /* Offset to create the border effect */
  left: -10px; /* Align with the triangle edges */
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid white; /* Same color as the container */
  z-index: 3;
}

.floating-scrollable-wrapper {
  max-height: 500px; /* Limit height for scrolling */
  overflow-y: auto; /* Allow vertical scrolling only in the wrapper */
  overflow-x: hidden; /* Avoid horizontal overflow */
}


.container-content {
  position: relative;
  z-index: 1; /* Ensure content is above the triangle */
}



