/* src/App.css */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  display: flex;
  flex-direction: column;
}

.top-section {
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
}

.scrollable-content {
  background-color: #fffbf3; /* Light background color for the scrollable content */
  overflow-y: auto;
}

