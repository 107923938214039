/* General Styles */
.carna4-card {
  font-family: 'Arial', sans-serif;
  background-color: #fde6c5;
  padding: 30px 0 0 30px;
  max-width: 100%;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.carna4-header {
  display: flex;
  align-items: flex-end; /* Align items at the bottom of the container */
}

.carna4-header-logo {
  height: 66px;
  margin-right: 10px;
  display: block;
}

.carna4-title {
  font-family: "amiri", sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size: 33px;
  color: black;
  margin: 0;
  margin-bottom: 5px; /* Add 10px of space between the text and the baseline */
  line-height: 1; /* Ensure no extra spacing inside the text */
}



/* Main Section */
.carna4-main {
  display: flex;
  align-items: flex-start;
}

.carna4-left {
  width: 54%; /* Explicitly set both sections to occupy 50% of the width */
  flex: none; /* Prevent automatic resizing */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.carna4-right {
  flex: 1;
  position: relative;
  bottom: 40px;
  height: auto;
  width: auto;
}


.carna4-description {
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 1.6;
}


.carna4-features {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to new rows */
  gap: 40px; /* Maintain the gap between items */
  margin-top: 70px;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start; /* Align items at the top (not stretched) */
}

.carna4-feature-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  align-self: stretch; /* Enforce equal height individually */
}

.carna4-feature {
  background-color: #fff;
  padding: 50px 20px 20px 20px;
  border-radius: 6px;
  border: 2px solid #f5cf50;
  flex: 1;
  font-size: 14px;
  position: relative;
  color: #ec0200;
  font-style: italic;
  z-index: 10;
  width: 160px; /* Predefined fixed width for all boxes */
  flex-shrink: 0; /* Prevents the boxes from shrinking */
  box-sizing: border-box;
}

.carna4-feature-eagle {
  position: absolute;
  top: -40%;
  right: 10%;
  z-index: 20;
}

.carna4-feature-top-left {
  position: absolute;
  top: -32%;
  left: -10%;
  z-index: 5;
}

.carna4-top-right {
  position: absolute;
  top: -30%;
  left: 24%;
  z-index: 5;
}

.carna4-bottom-left {
  position: absolute;
  bottom: -24%;
  left: -11%;
  z-index: 5;
}

.carna4-feature-alphabets {
  position: absolute;
  right: -16%;
  top: -30%;
  z-index: 20;
}

.carna4-feature-clipboard {
  position: absolute;
  right: 65%;
  top: -33%;
  z-index: 20;
  height: 110px;
}



.carna4-purchase {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-right: 200px;
  font-size: 14px;
  align-items: center;
}

.carna4-purchase:last-child {
  margin-top: 20px;
  margin-bottom: 40px
}

.carna4-size-one {
  border-radius: 6px;
  border: 1px solid #ec0200;
  color: #ec0200;
  text-align: center;
  padding: 8px 20px;
}

.carna4-size-two {
  border-radius: 6px;
  border: 1px solid #acacac;
  color: #747474;
  text-align: center;
  padding: 8px 20px;
}

.carna4-recipe.selected {
  border-radius: 6px;
  border: 1px solid #ec0200;
  color: #ec0200;
  text-align: center;
  padding: 8px 20px;
}

.carna4-recipe {
  border-radius: 6px;
  border: 1px solid #acacac;
  color: #747474;
  text-align: center;
  padding: 8px 20px;
}

.carna4-quantity-control {
  border-radius: 6px;
  border: 1px solid #000;
  color: #000;
  text-align: center;
  padding: 8px 8px;
  gap: 10px;
}

.carna4-add-to-cart {
  border-radius: 6px;
  border: none;
  background-color: #ec0200;
  color: #fff;
  text-align: center;
  padding: 8px 20px;
}

.carna4-decrease {
  margin-right: 15px;
}

.carna4-increase {
  margin-left: 15px;
}
