/* Main Container */
.premium-pet-food-ad-container {
  display: flex;
  position: relative;
  background-color: #FFFAF5;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  align-items: center;
  height: 275px;
  width: 100%;
  box-sizing: border-box;
}

/* Red Shape Background */
.premium-pet-food-ad-red-shape-container {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 1;
}

.premium-pet-food-ad-red-shape {
  height: 100%;
  width: auto;
}

/* Paw Prints */
.premium-pet-food-ad-paw-prints {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 0;
  opacity: 0.2;
}

/* Content Section */
.premium-pet-food-ad-content {
  flex: 2;
  z-index: 2;
  position: relative;
}

.premium-pet-food-ad-heading {
  font-family: "acumin-variable",sans-serif;
  font-variation-settings: 'wght' 738, 'wdth' 55, 'slnt' 0;
  margin-top: 0;
  margin-left: 80px;
  font-size: 70px;
  color: #000;
  line-height: 0.9;
  position: relative;
  z-index: 2;
}

.inline-image {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.inline-dog-image {
  height: auto;
  position: absolute;
  width: 95px;
  top: 18px;
  left: 193px;
  z-index: -1;
}

.inline-dog-food-image {
  width: 80px; /* Adjust size as needed */
  height: auto;
  position: absolute;
  bottom: 27px;
  left: -91px;
}

.premium-pet-food-ad-description {
  color: #555;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 80px;
  margin-bottom: 0;
}

.premium-pet-food-ad-stats {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 80px;
}

.premium-pet-food-ad-stats-number {
  font-family: "acumin-variable",sans-serif;
  font-variation-settings: 'wght' 738, 'wdth' 55, 'slnt' 0;
  font-size: 25px;
  font-weight: bold;
  color: #ec0200; /* Red */
}

.premium-pet-food-ad-stats-plus {
  font-family: "acumin-variable",sans-serif;
  font-variation-settings: 'wght' 738, 'wdth' 55, 'slnt' 0;
  font-size: 25px;
  font-weight: bold;
  color: #ec0200; /* Red */
  margin-bottom: 6px;
  margin-left: -10px;
}

.premium-pet-food-ad-stats-label {
  font-family: "acumin-variable",sans-serif;
  font-variation-settings: 'wght' 738, 'wdth' 55, 'slnt' 0;
  font-size: 16px;
  color: #333;
}

/* Dog Image on Right */
.premium-pet-food-ad-images {
  flex: 1;
  position: relative;
  z-index: 2;
}

.premium-pet-food-ad-dog-right {
  top: -130px;
  position: absolute;
  right: 30px;
  width: 280px;
  left: -25px;
}
