/* src/components/Header.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5vh 10px;
  background-color: #fff;
  position: sticky; /* Use sticky positioning */
  top: 0; /* Stick to the top of the screen */
  z-index: 1000; /* Ensure it stays above other content */
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo {
  height: 45px;
  margin-right: 20px; /* This will be adjusted for smaller screens */
  margin-left: 30px;
}

.search-bar {
  display: flex;
  align-items: center;
  flex-grow: 1; /* Allows the search bar to take available space */
  margin-left: 20px; /* Initial space from the logo */
}

.search-input {
  border: 1px solid #ec0200;
  border-radius: 7px;
  padding: 5px 10px;
  outline: none;
  width: 100%; /* Takes full width of the search bar */
  max-width: 400px; /* Maximum width of the input */
  font-size: 16px;
}

.search-button {
  background: none;
  border: none;
  cursor: pointer;
  color: rgb(128, 128, 128);
  font-size: 16px;
  margin-left: -30px;
}

.support-user-cart-section {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: 10px;
}

.user-option {
  display: flex;
  align-items: center;
  color: #ec0200;
  margin-left: 20px;
  cursor: pointer;
}

.sign-in-option {
  display: flex;
  align-items: center;
  color: #ec0200;
  margin-left: 20px;
  cursor: pointer;
}

.icon {
  margin-right: 5px;
  font-size: 20px;
}

.cart-icon-container {
  position: relative; /* Make the container a positioning context */
  overflow: visible;  /* Allow the SVG to overflow */
  margin-right: 25px;
}

/* SVG Styles */
.cart-option .cart-icon-container .icon {
  position: absolute; /* Position the SVG */
  bottom: 0;          /* Align it to the bottom */
  overflow: visible;
  transform: translateY(10%);
}


.icon .circle {
  fill: #ec0200;
}

.icon .stroke {
  fill: #ffff;
}


/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  .search-bar {
    margin-left: 10px; /* Reduce space between the logo and search bar */
  }

  .logo {
    margin-right: 10px; /* Reduce right margin of the logo */
  }
}

@media (max-width: 480px) {
  .search-bar {
    margin-left: 1px; /* Minimal space between the logo and search bar */
  }

  .logo {
    margin-right: 1px; /* Minimal right margin of the logo */
  }
}
/* Header.css */
.support-user-cart-section .arrow-icon {
  stroke: #ec0200; /* Red color for the arrow */
  margin-left: 0; /* Space between text and arrow */
  margin-top: 4px;
}

.menu-bar .menu-list .menu-item {
    white-space: nowrap;
}

.menu-toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #ec0200; /* Red color for the hamburger icon */
  font-size: 24px; /* Icon size */
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.menu-toggle-button .menu-bar {
    display: none;
}
/* Styles for the dropdown menu */
.menu-toggle-button .menu-list {
  position: absolute;
  top: 100%; /* Position the dropdown right below the button */
  left: 0; /* Align dropdown to the right of the button */
  background-color: #3a3a3a;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 10px 0;
  margin: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  font-size: 16px;
  color: white;
  border-radius: 0 0 10px 10px;
  z-index: 1000;
  gap: 5px;
}

.menu-toggle-button .menu-item {
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap; /* Prevent text from wrapping */
  position: relative;
  transition: background-color 0.2s;
}

.menu-toggle-button .menu-item .arrow-icon {
  stroke: white;
}

.menu-toggle-button .menu-item:hover {
  background-color: #f5f5f5; /* Hover effect */
  color: #ec0200;
}

.menu-toggle-button .menu-item:hover .arrow-icon {
  stroke: #ec0200;
}

/* Hide nested dropdown content by default */
.menu-toggle-button .contains-dropdown .dropdown {
  display: none;
  position: absolute; /* Position the nested dropdown */
  left: 100%;
  top: 0;
  background-color: #444;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 150px; /* Set a minimum width for the nested dropdown */
  padding: 10px 0;
}

/* Show nested dropdown content on hover */
.menu-toggle-button .contains-dropdown:hover .dropdown {
  display: block;
}

/* Hover effect for the dropdown items */
.menu-toggle-button .dropdown p {
  padding: 10px 15px;
  margin: 0;
  cursor: pointer;
  color: white;
  transition: background-color 0.2s;
}

.menu-toggle-button .dropdown p:hover {
  background-color: white;
  color: #ec0200;
}