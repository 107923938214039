.nznatural-double-feature {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 40px 0;
  position: relative;
}

.nznatural-product-section-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 0;
}

.nznatural-product-section-left-product,
.nznatural-product-section-right-product {
  width: 25%;
  position: relative;
}

.nznatural-red-background {
  background-color: red;
  position: absolute;
  height: 220px;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
}

.nznatural-red-background.left-red-background {
  border-radius: 0 50px 50px 0;
  right: auto;
}

.nznatural-red-background.right-red-background {
  border-radius: 50px 0 0 50px;
  left: auto;
}

.nznatural-product-image {
    max-width: 60%;
    position: relative;
    transform: translateX(25%);
    z-index: 2;
}

.secondary-image {
    position: absolute;
    z-index: 2;
}

.secondary-image.meow-cat {
    bottom: 0;
    max-width: 60%;
    right: -15%;
  z-index: 1;
}

.secondary-image.woof-bowl {
    bottom: 13px;
    max-width: 29%;
    right: 15%;
}

.nznatural-title-description {
  width: 40%;
  text-align: center;
}

.nznatural-title-description h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 10px;
}

.nznatural-title-description p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.5;
  margin-top: 10px;
}

.nznatural-product-content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.nznatural-product-content-meow {
  width: 45%;
  text-align: left;
  padding-left: 50px;
}

.nznatural-product-content-woof {
  width: 45%;
  text-align: right;
  padding-right: 50px;
}

.nznatural-product-info-meow,  .nznatural-product-info-woof {
  margin-top: 20px;
}

.nznatural-product-info-meow h3,  .nznatural-product-info-woof h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-weight: bold;
}

.nznatural-product-info-meow h3 span,  .nznatural-product-info-woof h3 span {
    font-weight: normal;
    color: red;
}

.recipes, .sizes, .nznatural-quantity {
  display: flex;
  gap: 10px;
}

.recipes.woof-recipes, .sizes.woof-sizes, .nznatural-quantity {
  justify-content: flex-end;
}

.recipe-button, .size-button {
  padding: 5px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  font-size: 16px;
}

.recipe-button.active, .size-button.active {
  border-color: #ec0200;
  color: #ec0200;
  font-size: 16px;
}

.nznatural-add-to-cart-button {
  background: #ec0200;
  color: #fff;
  border: none;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.nznatural-add-to-cart-button:hover {
  background: #fd3635;
}

.nznatural-logo {
  height: 80px;
  width: auto;
}

.nznatural-description-image-background-watermark {
  position: absolute;
  height: 150px;
  width: auto;
  top: 42%;
  right: 43%;
  opacity: 21%;
}

.sizes-and-cart-woof, .sizes-and-cart-meow {
  display: flex;
  gap: 10px;
}

.sizes-and-cart-woof {
  justify-content: flex-end;
}

.nznatural-quantity {
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 6px;
  color: #000;
  gap: 10px;
  text-align: center;
  align-items: center;
  font-size: 16px;
}

.nznatural-quantity .decrease, .nznatural-quantity .increase {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.nz-natural-ad-icon {
  z-index: 2;
  height: 80px;
  width: 80px;
}

.nz-natural-svg-container {
  position: absolute;
  bottom: 25%;
  display: flex;
  align-items: flex-end;
  gap: 30px;
}


