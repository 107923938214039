/* Overlay to center the pop-up */
.signin-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dim background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Pop-Up Box */
.signin-popup {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  width: 500px;
  padding: 20px;
  text-align: center;
  border: 1px solid #d4d4d4;
}

/* Header */
.signin-popup-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signin-popup-logo {
  height: 60px;
  margin-bottom: 10px;
}

.signin-popup-content {
  padding: 10px 25px;
  border: 1px solid red;
  border-radius: 10px;
}

.signin-popup-title {
  font-size: 1.5rem;
  color: #c00;
  font-family: "Arial", sans-serif;
}

/* Content */
.signin-popup-content h2 {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: black;
  text-align: left;
  font-family: "acumin-variable",sans-serif;
  font-variation-settings: 'wght' 442, 'wdth' 100, 'slnt' 0;
}

/* Email Input */
.signin-popup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.signin-popup-label {
  font-size: 0.9rem;
  color: black;
  margin-bottom: 5px;
  text-align: left;
  width: 100%;
}

.signin-popup-input:focus {
  border-color: #f10000;
}

/* Continue Button */
.signin-popup-continue-btn {
  width: 100%;
  padding: 10px;
  background: #f10000;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 20px;
}

.signin-popup-continue-btn:hover {
  background: #d90000;
}

/* Divider */
.signin-popup-divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.signin-popup-divider .line {
  flex: 1;
  height: 1px;
  background: #ccc;
}

.signin-popup-divider .or-text {
  margin: 0 10px;
  color: #999;
  font-size: 1.2rem;
  font-weight: bold;
}

/* Social Buttons */
.signin-popup-social-btn {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 50px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
}

.google-btn {
  border: 1px solid #ddd;
  background: white;
  color: #555;
}

.google-btn .google-logo {
  width: 20px;
  height: 20px;
  background: url("https://cdn-icons-png.flaticon.com/512/2991/2991148.png") no-repeat center;
  background-size: contain;
  margin-right: 10px;
}

.apple-btn {
  border: 1px solid #ddd;
  background: white;
  color: black;
}

.apple-btn .apple-logo {
  width: 20px;
  height: 20px;
  background: url("https://cdn-icons-png.flaticon.com/512/179/179309.png") no-repeat center;
  background-size: contain;
  margin-right: 10px;
}

.apple-btn:hover,
.google-btn:hover {
  background: #f5f5f5;
}


/* Container for email input */
.signin-email-address-input-container {
  position: relative;
  width: 100%;
  border: 1px solid #000; /* Border for the container */
  border-radius: 10px; /* Rounded corners */
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

/* Label for the input */
.signin-popup-label {
  position: absolute;
  top: -10px; /* Move the label above the input */
  left: 15px; /* Padding from the left */
  background: #fff; /* White background to cut into the border */
  padding: 0 5px; /* Add space around the label text */
  font-size: 1rem; /* Label font size */
  color: #000; /* Label text color */
  width: auto;
}

/* Input field styling */
.signin-popup-input {
  border: none; /* Remove border from the input */
  outline: none; /* Remove the default focus outline */
  font-size: 1rem; /* Input font size */
  width: 100%; /* Full width */
  margin-top: 8px; /* Adjust spacing */
}


/* Container for email input */
.signin-password-input-container {
  position: relative;
  width: 100%;
  border: 1px solid #000; /* Border for the container */
  border-radius: 10px; /* Rounded corners */
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.forgot-login-credentials-container {
  align-self: flex-start;
  text-align: left;
}

.forgot-email, .forgot-password {
  color: blue;
  text-decoration: underline;
  margin-top: 5px;
  display: flex;
  cursor: pointer;
}