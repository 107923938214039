/* src/components/HeaderOptions.css */
.header-options {
  display: flex;
  justify-content: space-between;
  gap: 7px; /* Space between blocks */
  height: 100%; /* Occupy the full height of the parent container */
  margin: 0; /* Remove any margin around the container */
  padding: 0; /* Remove any padding around the container */
  background-color: #3a3a3a;
}


.option-block {
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}


.text-container {
  margin-left: 2vw;
  font-size: 1.4vw;
  margin-bottom: 2vh;
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  text-align: left;
  white-space: nowrap;
  color: #fff;
  padding: 0.5vh 3vw 0.5vh 2vw;
}

.text-container:hover {
  background-color: #ec0200;
  border-radius: 5px;
  cursor: pointer;
}
