/* src/components/FullTwoImagesHorizontalAd.css */

.two-images-ad-wrapper {
  margin-top: 5vw;
}


.two-images-ad-wrapper .two-images-full-horizontal-ad {
  display: flex;
  align-items: center;
  background-color: #ec0200; /* Red background */
  color: white;
  position: relative;
  padding: 30px;
}

.two-images-ad-wrapper .ad-content {
  margin-left: 30%;
  max-width: 35%;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.two-images-ad-wrapper .ad-title {
  font-size: 2.2vw;
  font-weight: bold;
  margin-bottom: 20px;
}

.two-images-ad-wrapper .ad-description {
  font-size: 1.2vw;
  line-height: 1.5;
}

.ad-image-container-overflow {
  position: absolute;
  left: 3%;
  bottom: -10px;
  height: 140%; /* Fixed width for the image container */
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.ad-image-container-no-overflow {
  position: absolute;
  right: 3%;
  bottom: 0;
  height: 100%; /* Adjust height based on content */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden; /* Prevent overflow */
}

.ad-image-container-overflow .ad-image {
  max-width: 100%;
  max-height: 100%;
  overflow: visible;
  object-fit: contain; /* Maintain aspect ratio */
}

.ad-image-container-no-overflow .ad-image {
  height: 100%;
  object-fit: contain; /* Maintain aspect ratio */
}

.striped-line {
  height: 10px; /* Adjust the height as needed */
  background: repeating-linear-gradient(
    90deg,
    #ffb6b6, /* Darker red color */
    #ffb6b6 100px, /* Width of each dark stripe */
    #ffd2d2 100px, /* Light red color */
    #ffd2d2 200px /* Width of each light stripe */
  );
}
