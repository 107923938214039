/* Overall Container */
.category-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 20px;
  background-color: #fff;
}

/* Header Section */
.category-header {
  text-align: center;
  margin-bottom: 20px;
}

.category-header h1 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #333;
}

.category-header p {
  font-size: 1.2rem;
  color: #555;
}

/* Main Content: Side-by-Side Layout */
.category-page-main {
  display: flex;
  flex: 1;
  gap: 20px;
}

/* Left Section: 10% */
.category-page-left-section {
  flex: 1;
  box-sizing: border-box;
  padding-left: 20px;
}

/* Right Section: 90% */
.category-page-right-section {
  flex: 5;
  box-sizing: border-box;
  padding: 20px;
}


/* Full-Width Section */
.category-page-full-width-section {
  width: 100%;
  margin-top: 20px;
}

/* Product Grid */
.category-page-product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.category-page-full-width-section .product-collection-wrapper {
  background: transparent;
}

.category-page-full-width-section .collection-title {
  text-align: left;
  padding-left: 30px;
}

.category-page .pagination-bar {
  margin-top: 30px;
}