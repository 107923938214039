/* Wrapper for the header */
.master-category-header {
  width: 100%;
  box-sizing: border-box;
}

/* Breadcrumb styling */
.master-category-breadcrumb {
  font-size: 1rem;
  margin-bottom: 20px;
  text-align: left;
}

.master-category-collection-title h1 {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
}

.master-category-header .pet-collection-item {
  width: auto;
}

.master-category-header .pet-collection-image-container {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  object-fit: contain;
  border: 1px solid #ec0200;
}
