/* Container Styling */
.slow-feeder-ad-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 300px; /* Adjust height to match image proportions */
  overflow: hidden;
  padding: 0 20px;
}
.outwardhound-dog-and-puzzlefeeder {
  position: absolute;
  left: 1%;
  top: 10%;
  width: 260px;
}
.outwardhound-dog {
  position: absolute;
  right: 2%;
  bottom: -2%;
  z-index: 1;
  width: 268px;
}
.outwardhound-puzzlefeeder {
  bottom: 4%;
  position: absolute;
  right: 18%;
  width: 250px;
}

/* Background Shapes */
.slow-feeder-ad-container .background-shape {
  position: absolute;
  background-color: #8D0B41; /* Light purple color */
  border-radius: 50%;
  opacity: 1;
}

.slow-feeder-ad-container .left-shape {
  height: 265px;
  left: -7%;
  top: -38%;
  width: 265px;
}

.slow-feeder-ad-container .right-shape {
  bottom: -92%;
  height: 540px;
  right: -8%;
  width: 540px;
}

/* Content Wrapper */
.slow-feeder-ad-container .content-wrapper {
  position: relative;
  text-align: left;
  width: 43%;
  z-index: 2;
  align-items: flex-start;
  margin-left: -17%;
  flex-direction: column;
}

/* Title Styling */
.slow-feeder-ad-container .title {
  font-size: 2rem;
  font-weight: bold;
  color: #8D0B41; /* Dark purple */
  margin: 0 0 10px;
  line-height: 1.2;
}


/* Subtitle Styling */
.slow-feeder-ad-container .subtitle {
  font-size: 1.25rem;
  color: #8D0B41; /* Lighter purple */
  text-align: left;
  margin: 0;
}

/* Description */
.slow-feeder-ad-container .description {
  font-size: 1rem;
  color: #5a5a5a;
  margin-bottom: 20px;
  line-height: 1.2;
  margin-top: 10px;
  text-align: justify;
}

/* Shop Now Button */
.slow-feeder-ad-container .shop-now-button {
  padding: 5px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #8D0B41;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease-in-out;
  margin: 0;
}

.slow-feeder-ad-container .shop-now-button:hover {
  background-color: #8D0B41; /* Slightly lighter hover color */
}
