/* General container styling */
.letter-sequence-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 200px;
  position: relative;
  background-color: transparent;
  overflow: hidden;
  padding: 20px 20px 40px;
}

/* Step 1: Envelope icon */
.icon-container {
  width: 160px;
  position: absolute;
  z-index: 2; /* Envelope stays on top */
}

/* Step 2: Letter sliding out */
.letter-coming-out {
  position: absolute;
  z-index: 1; /* Letter slides out below the envelope */
  animation: letter-slide 1.5s ease-in-out forwards; /* Animate immediately */
}

/* Step 3: Final letter icon */
.final-icon {
  position: absolute;
}

/* Hover effect: Replay animation */
.letter-sequence-container:hover .letter-coming-out {
  animation: letter-slide 1.5s ease-in-out forwards;
}

/* Keyframes for animations */
@keyframes letter-slide {
  0% {
    transform: translateY(36px) scale(1); /* Start slightly below envelope */
    opacity: 1;
  }
  100% {
    transform: translateY(-34px) scale(1); /* Stop just below the envelope */
    opacity: 1;
  }
}

/* Specific icon styling */
.letter-envelope-step1-icon {
  width: 160px;
}

.letter-letter-step2-icon {
  width: 130px;
}

.letter-final-icon {
  width: 160px;
}

.newsletter-envelope-shadow {
  width: 200px;
  position: absolute;
  z-index: 3;
  bottom: 10px;
  opacity: 0.7;
}