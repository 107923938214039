.staffs-pick-container {
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: #fffbf3;
  position: relative;
}

.staffs-pick-title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 10px;
}

.shop-all-link {
  display: block;
  text-align: center;
  font-size: 18px;
  color: #333;
  text-decoration: underline;
  margin-bottom: 20px;
}

.staffs-pick-wrapper {
  display: flex;
  align-items: flex-start; /* Align items to the top of the row */
  gap: 20px; /* Space between the first item and the product collection */
  margin-left: 20px;
  margin-bottom: 30px;
}

.first-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  margin-bottom: 20px;
  margin-left: 10px;
  justify-content: center; /* Center horizontally */
  overflow: contain;
}

.first-item-image {
  width: 100%;
  border-radius: 10px;
}

.first-item-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.first-item-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 5px;
}

.first-item-text {
  font-size: 14px;
  color: #666;
  margin: 0;
}

.staffs-pick-container .product-collection-wrapper {
  display: flex;
  align-items: center;
  flex: 1; /* Allow the product collection to grow and take available space */
  overflow: hidden;
  position: relative;
}

.staffs-pick-container .scroll-button {
  background-color: #ec0200;
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  opacity: 0.4;
  transition: opacity 0.3s;
  top: 180px;
}

.staffs-pick-container .scroll-button:hover {
  opacity: 0.8;
}

.staffs-pick-container .scroll-button.left {
  position: absolute;
  left: 20px;
  z-index: 1;
}

.staffs-pick-container .scroll-button.right {
  position: absolute;
  right: 20px;
  z-index: 1;
}

.staffs-pick-container .product-collection {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  padding-bottom: 10px;
  scroll-behavior: smooth;
}



.deal-flag {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #9e0000;
  color: white;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 0 0 10px 0;
}

.bestseller-flag {
  position: absolute;
  top: 0;
  left: 0;
  background-color: green;
  color: white;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 0 0 10px 0;
}

.staffs-pick-container .product-image-container {
  width: 100%;
  height: 180px;
  margin-bottom: 5px;
  border-bottom: 8px solid #e5e5e5;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}
.staffs-pick-container .product-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.staffs-pick-container .product-content {
  text-align: left;
  padding: 10px;
}

.staffs-pick-container .product-title {
  font-size: 14px;
  margin: 0;
  margin-bottom: 5px;
}

.staffs-pick-container .product-author {
  font-size: 12px;
  color: #ec0200;
  margin-bottom: 5px;
  align-items: center;
  display: flex;
}

.staffs-pick-container .product-author .author-logo {
  width: 70%;
}

.staffs-pick-container .product-rating {
  font-size: 12px;
  color: #666;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.star-icon {
  color: #ffc107;
  margin-right: 2px;
}

.product-save {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 3px;
}

.staffs-pick-container .product-save .save-icon {
  margin-right: 5px;
  color: #ec0200;
  font-size: 14px;
}




.staffs-pick-container .product-card {
  background-color: #fff;
  border-radius: 0 50px 0 0;
  border: 5px solid #bababa;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 200px;
  height: 340px;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

.hidden-controls {
  display: none; /* Hide controls by default */
  margin-top: 10px;
  margin-bottom: 10px;
}

.staffs-pick-container .product-card:hover .hidden-controls {
  display: flex; /* Show controls on hover */
  flex-direction: column;
  align-items: center;
}

.staffs-pick-container .product-card:hover {
  height: auto;
}


