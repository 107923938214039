/* General Container */
.skouts-honor-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

/* Background Sections */
.background-section {
  padding: 10px 50px 50px 20px;
  width: 100%;
}

.red-background {
  background-color: #ec0200;
  height: 250px;
  position: relative;
}

.white-background {
  background-color: #fff;
  height: 70px;
  position: relative;
  color: #a10000;
  text-align: center;
}

/* Grid Overlay */
.red-background::before,
.white-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 50px 50px; /* Grid size */
  pointer-events: none;
  z-index: 0;
}

.red-background::before {
  background-image:
    linear-gradient(to right, rgba(255, 255, 255, 0.07) 2px, transparent 2px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.07) 2px, transparent 2px);
}

.white-background::before {
  background-image:
    linear-gradient(to right, rgba(140, 135, 135, 0.05) 2px, transparent 2px),
    linear-gradient(to bottom, rgba(140, 135, 135, 0.05) 2px, transparent 2px);
}

/* White SVG Pattern */
.background-pattern {
  height: auto;
  left: -75%;
  margin: auto;
  position: absolute;
  right: 0;
  top: -10%;
  width: 31%;
  z-index: 2;
}

/* Content Wrapper */
.content-wrapper {
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  position: relative;
  z-index: 3;
}

/* Animals Container */
.animals-container {
  position: absolute;
  top: 7%;
  left: 0;
}

.dog-cat-image {
  height: auto;
  max-width: 40%;
}

/* Text Content */
.text-content {
  text-align: center;
  max-width: 40%;
  transform: translateX(70%);
}

.main-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
}

.description {
  font-size: 1rem;
  line-height: 1.5;
  color: white;
}

/* Products Container */
.products-container {
  position: absolute;
  right: -14%;
  top: 12%;
}

.products-image {
  max-width: 60%;
  height: auto;
}

/* Bottom Text */
.bottom-text {
  color: #a10000; /* Text color */
  font-size: 1rem; /* Font size */
  line-height: 1.5; /* Line height for better readability */
  text-align: justify; /* Justify alignment */
  text-align-last: center; /* Ensures the last line is centered */
  margin: 0 auto; /* Center horizontally */
  max-width: 60%; /* Constrain the text width */
  margin-left: 30%;
  padding-top: 15px;
  font-weight: bold; /* Bold font */
}

.skouts-honor-shop-now-button {
  padding: 5px 20px;
  background-color: white;
  border: none;
  color: #ec0200;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 10px;
  margin-top: 20px;
}
