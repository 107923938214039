.carna4-product {
  align-items: flex-end;
  background-color: #fde6c5;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 610px;
  box-sizing: border-box;
  position: relative;
}

.carna4-product-box {
  box-sizing: border-box;
  position: relative;
  background-color:#cc0001;
  border-radius: 0 50% 100px 50%;
  color: #fff;
  /* margin-bottom: 40px; */
  padding: 20px 80px 20px 4px;
  text-align: right;
  width: 350px;
  right: 40%;
}

.carna4-product .carna4-product-tag,
.carna4-product .carna4-product-bag,
.carna4-product .carna4-product-food {
  max-width: 100%; /* Ensure the images don't overflow the parent */
  display: block;
  position: absolute;
}

.carna4-product .carna4-product-tag {
  height: 22%;
  right: 0;
  top: 5%;
  z-index: 10;
}

.carna4-product .carna4-product-bag {
  bottom: -16%;
  height: 110%;
  right: 1%;
}

.carna4-product .carna4-product-food {
  bottom: -13%;
  height: 30%;
  right: -1%;
}

.carna4-product-box-spiral {
  width: 38px;
  left: -20px;
  position: absolute;
  top: -6px;
  z-index: 1;
}

.carna4-product-title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 10px;
}

.carna4-product-description {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.carna4-product-price {
  font-size: 26px;
  font-weight: 700;
}






